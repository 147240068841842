/**
 * CANVAS.JS
 * Manage canvas animation (introduction section)
 ************************************ */

//= require_self

(function($) { 

    $(window).load(function() {

        if (!!!document.createElement("canvas").getContext) return;
        
        window.requestAnimFrame = (function(){
          return  window.requestAnimationFrame       ||
                  window.webkitRequestAnimationFrame ||
                  window.mozRequestAnimationFrame    ||
                  function( callback ){
                    window.setTimeout(callback, 1000 / 60);
                  };
        })();
        
        var width, height, strokecolor, canvas, ctx, points, target;

        initHeader();
        initAnimation();
        addListeners();

        function initHeader() {
            
            /**
             * VARS
             ********************************* */
            width = $('#main').width();
            height = $('#main').height();
            target = {x: width/2, y: height/2};
            
            canvas = document.getElementById('canvas');
            strokecolor = canvas.getAttribute("data-strokecolor");        
            canvas.width = width;
            canvas.height = height;
            ctx = canvas.getContext('2d');

            /**
             * GRID
             * - Création des points par rapport à une grille
             * = 3 colonnes / 3 lignes
             * = density : augmente la densité des points au centre
             ********************************* */
            // create points
            points = [];

            for(var x = 0; x < width; x = x + width/2) {
                // console.log('new col');
                density = 1;
                for(var y = 0; y < height; y = y + height/6) {

                    z = 0;
                    
                    // console.log(density);

                    while(z < density) {
                        var px = x + Math.random()*width/2;
                        var py = y + Math.random()*height/4;
                        var p = {x: px, originX: px, y: py, originY: py };
                        points.push(p);
                        z++;
                        // console.log('created ' + z + ' point(s)');
                    }
                }
            }

            /**
             * FOREACH POINT
             * - Création des cercles pleins
             * = c : taille des cercles et couleur/opacité
             ********************************* */
            // assign a circle to each point
            for(var i in points) {
                var c = new Circle(points[i], 20+Math.random()*120);
                points[i].circle = c;
            }

            $('.main__content').addClass('main__content--visible');
        }

        // Event handling
        function addListeners() {
            window.addEventListener('mousemove', mouseMove);
            window.addEventListener('resize', resize);
        }

        function mouseMove(e) {
            var posx = posy = 0;
            if (e.pageX || e.pageY) {
                posx = e.pageX - width*0.1;
                posy = e.pageY - height*0.1;
            }
            else if (e.clientX || e.clientY) {
                posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
            }
            target.x = posx;
            target.y = posy;
        }

        function resize() {
            width = window.innerWidth;
            height = window.innerHeight;        
            canvas.width = width;
            canvas.height = height;
        }

        // animation
        function initAnimation() {
            animate();
            for(var i in points) {
                shiftPoint(points[i]);
            }
        }

        /**
         * ANIMATE
         * - Réactions au survol de la souris
         * = active : opacité des cercles et des lignes au survol selon la distance
         ********************************* */
        function animate() {
            ctx.clearRect(0,0,width,height);
            for(var i in points) { 
                // detect points in range
                if(Math.abs(getDistance(target, points[i])) < 5000) { 
                    points[i].active = 0.5;
                    points[i].circle.active = 0.45;
                } else if(Math.abs(getDistance(target, points[i])) < 25000) { 
                    points[i].active = 0.35;
                    points[i].circle.active = 0.4;
                } else if(Math.abs(getDistance(target, points[i])) < 50000) {
                    points[i].active = 0.3;
                    points[i].circle.active = 0.35;
                } else {
                    points[i].active = 0.25;
                    points[i].circle.active = 0.3;
                }

                points[i].circle.draw();
            }
            requestAnimFrame(animate);
        }

        /**
         * SHIFT POINT
         * - Amplitude du mouvement des points
         * x, y : coordonnées aléatoires x 12
         ********************************* */
        function shiftPoint(p) {
            TweenLite.to(p, 1+1*Math.random(),
               {x: p.originX-10+Math.random()*20,
                y: p.originY-10+Math.random()*20,
                ease:Sine.easeInOut,
                onComplete: function() {
                    shiftPoint(p);
                }});
        }

        function Circle(pos,rad) {
            var _this = this;

            // constructor
            (function() {
                _this.pos = pos || null;
                _this.radius = rad || null;
            })();

            this.draw = function() {
                if(!_this.active) return;
                ctx.beginPath();
                ctx.arc(_this.pos.x, _this.pos.y, _this.radius, 0, 2 * Math.PI, false);
                ctx.strokeStyle = 'rgba('+strokecolor+','+ _this.active+')';
                ctx.stroke();
            };
        }

        // Util
        function getDistance(p1, p2) {
            return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
        }
    });
})(jQuery);
