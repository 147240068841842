$(function() {
  'use strict';

  $('.js-filters-item').on('click', function(event) {
    event.preventDefault();

    var $this    = $(this),
        id       = $this.data('id'),
        $filters = $('.js-filters-item'),
        $items   = $('.js-projects-item'),
        $targets = (id == "all") ? $items : $('.js-projects-item[data-category=' + id + ']');

    $filters.not($this).removeClass('is-active');
    $this.addClass('is-active');

    $items.not($targets).fadeOut();
    $targets.fadeIn();
  });

});
