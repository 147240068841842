$(document).ready(function() {

  $('.js-projects-item').each(function() {
    var distance = (Math.random() * 31) + 10;

    if(Math.random() > 0.5) {
      $(this).addClass('is-lefty').css('left', distance + "%");
    }
    else {
      $(this).addClass('is-righty').css('right', distance + "%");
    }
  });
});
